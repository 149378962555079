<template>
    <div>
        <b-card class="rounded-lg">
            <b-card-text class="d-flex">
                {{ $t('ErrorLogCard.title') }}
            </b-card-text>

            <b-card-text>
                <b-card no-body>
                    <div class="m-2">
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                                class="d-flex align-items-center justify-content-start mb-1 px-0 mb-md-0"
                            >
                                <b-form-input
                                    v-model="searchQuery"
                                    :placeholder="
                                        $t('ErrorLogCard.searchPlaceHolder')
                                    "
                                />
                            </b-col>

                            <b-table
                                ref="table"
                                responsive
                                :items="items"
                                :fields="tableColumns"
                                primary-key="id"
                                show-empty
                                :sort-desc.sync="sortDesc"
                                :sort-by.sync="sortBy"
                                :empty-text="
                                    $t('ErrorLogCard.noMatchRecordFound')
                                "
                                class="position-relative mt-2"
                            >
                                <template #head(checkbox) style="width: 20px">
                                    <div class="d-flex" style="margin-top: 1px">
                                        <b-form-checkbox
                                            v-model="selected"
                                            value="A"
                                            class="custom-control-primary my-auto"
                                        >
                                        </b-form-checkbox>
                                    </div>
                                </template>
                                <template #head(date)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                        >
                                            {{
                                                $t(
                                                    `ErrorLogCard.${data.field.label}`
                                                )
                                            }}
                                        </span>

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(invoice)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                        >
                                            {{
                                                $t(
                                                    `ErrorLogCard.${data.field.label}`
                                                )
                                            }}
                                        </span>

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(activity)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                        >
                                            {{
                                                $t(
                                                    `ErrorLogCard.${data.field.label}`
                                                )
                                            }}
                                        </span>

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(error)="data">
                                    <div class="d-flex">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 5px"
                                        >
                                            {{
                                                $t(
                                                    `ErrorLogCard.${data.field.label}`
                                                )
                                            }}
                                        </span>

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>

                                <!-- Column: date -->
                                <template #cell(checkbox)="data">
                                    <b-form-checkbox v-model="data.value">
                                    </b-form-checkbox>
                                </template>

                                <!-- Column: date -->
                                <template #cell(date)="data">
                                    <div class="d-flex">
                                        <p class="font-weight-bold mb-0">
                                            {{ data.value }}
                                        </p>
                                    </div>
                                </template>

                                <!-- Column: Issued Date -->
                                <template #cell(error)="data">
                                    <span class="text-nowrap checking">
                                        {{ data.value }}
                                    </span>
                                </template>
                            </b-table>
                        </b-row>
                    </div>

                    <!-- Table -->

                    <div class="mx-2 mb-2">
                        <b-row align-h="end">
                            <!-- Pagination -->
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :total-rows="pagination.totalInvoices"
                                    :per-page="pagination.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import {dateMixins} from '@/mixins/dateMixins';
import vSelect from 'vue-select';

export default {
    mixins: [dateMixins],
    components: {
        vSelect,
    },
    data() {
        return {
            selected: false,
            searchQuery: '',
            sortDesc: null,
            items: [],
            tableColumns: [
                {
                    key: 'checkbox',
                    tdClass: 'bTableThStyle',
                    thStyle: {width: '20px', padding: '0.72rem 10px'},
                },
                {
                    key: 'date',
                    label: 'dateHead',
                    tdClass: 'bTableTdDateStyle',
                    thStyle: {padding: '0.72rem 0'},
                },
                {
                    key: 'invoice',
                    label: 'invoiceHead',
                },
                {
                    key: 'activity',
                    label: 'activityHead',
                },
                {
                    key: 'error',
                    label: 'errorHead',
                },
            ],
            pagination: {
                currentPage: 1,
                totalInvoices: 0,
                perPage: 20,
            },
            sortBy: '',
        };
    },

    watch: {
        searchQuery(value) {
            this.$useJwt.failuresList({params: {search: value}}).then(res => {
                const {results} = res.data;

                const {count} = res.data;

                this.pagination.totalInvoices = count;

                this.renderErrorList(results);
            });
        },
        'pagination.currentPage': {
            handler(page) {
                this.$useJwt.failuresList({params: {page: page}}).then(res => {
                    const {results} = res.data;

                    const {count} = res.data;

                    this.pagination.totalInvoices = count;

                    this.renderErrorList(results);
                });
            },
        },
    },

    methods: {
        renderErrorList(value) {
            let dataArray = [];

            value.forEach(element => {
                dataArray.push({
                    uuid: element.uuid,
                    checkbox: false,
                    date: this.getDateStringFormat(element.created_at),
                    invoice: element.invoice_number,
                    error: element.error_message,
                    activity: element.action,
                });
            });

            this.items = dataArray;
        },
        getAllFailures() {
            this.$useJwt.failuresList().then(res => {
                const {results} = res.data;

                const {count} = res.data;

                this.pagination.totalInvoices = count;

                this.renderErrorList(results);
            });
        },

        sortingChanged(sortBy, desc) {
            this.sortBy = sortBy.column;

            this.sortDesc = desc;
        },
    },

    mounted() {
        this.getAllFailures();
    },
};
</script>

<style scoped lang="scss">
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
